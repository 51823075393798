import { useMemo } from "react";
import { calcAngleDegrees } from "./Mn_SingleConnector_func";

export default function ConnectorHdTl ({handleMouseDown, id, line, chek}) {

    let points = useMemo(() => [...line['pts']],[line]);
    let len = points.length;
    let [xi,yi] = points[0], [xf, yf] = points[len-1];

    let angl1, angl2, trnsfrm1, trnsfrm2, TlCrclSmallStyle, HdCrclSmallStyle;

    if (chek) {
        angl1 = calcAngleDegrees(points[0],points[1]);
        angl2 = calcAngleDegrees(points[len-2],points[len-1]);
        trnsfrm1 =`rotate(${angl1}deg)`;
        trnsfrm2 =`rotate(${angl2}deg)`;
        TlCrclSmallStyle = { left: (xi - 6) + 'px', top: (yi - 3) + 'px' , transform: trnsfrm1, transformOrigin: '6px 3px' }
        HdCrclSmallStyle = { left: (xf ) + 'px', top: (yf - 3) + 'px' , transform: trnsfrm2, transformOrigin: '0 3px' }
    }
    
    let TlCrclBigStyle = { left: (xi - 10) + 'px', top: (yi - 10) + 'px' }
    let HdCrclBigStyle = { left: (xf - 10) + 'px', top: (yf - 10) + 'px' }

  return (

    <>

        {chek && <div className="HdTlCircleSmall" style={TlCrclSmallStyle} ></div>}
        {chek && <div className="HdTlCircleSmall" style={HdCrclSmallStyle} ></div>}
        <div className="HdTlCircleBig cnctnTail" onMouseDown={handleMouseDown} data-id={id} style={TlCrclBigStyle} ></div>
        <div className="HdTlCircleBig cnctnHead" onMouseDown={handleMouseDown} data-id={id} style={HdCrclBigStyle} ></div>
        
    </>
    
  );
}