import { useState, useMemo, createContext, useContext, useCallback} from 'react';
import { x_wrnStyleFunc, getTextById, x_globalElementsUpdater, x_globalElementsModifier, x_panelNdCanvasSizeFunc, x_globalElementsPointsReset, x_globalElementsIdsReset, x_globalElementsModifier2, x_globalLanesModifier, x_globalLanesUpdater} from './Master_func'
import { idSvgMap } from './components/data/stdClsFnc';

const MyContextHd = createContext(null);
const MyContextSp = createContext(null);
const MyContextMn = createContext(null);

export function useMyContextHd() {return useContext(MyContextHd)};
export function useMyContextSp() {return useContext(MyContextSp)};
export function useMyContextMn() {return useContext(MyContextMn)};

export function ContextWrapper({children}) {

  const [globalElements, setGlobalElements] = useState({});
  const [globalElementIdMaps, setGlobalElementIdMaps] = useState({});
  const [globalElementTexts, setGlobalElementTexts] = useState({});
  const [globalLanes, setGlobalLanes] = useState({});
  const [slctdElemId, setSlctdElemId] = useState('');
  const [showDummy, setShowDummy] = useState(false);
  const [slctdIdList, setSlctdIdList] = useState([]);
  const [showDummyList, setShowDummyList] = useState(false);

  const [collapsePanel, setCollapsePanel] = useState(false);
  const [widthCounter, setWidthCounter] = useState(0);
  const [heightCounter, setHeightCounter] = useState(0);

  const [wrnShow, setWrnShow] = useState(false);
  const [wrnId, setWrnId] = useState('');
  const [wrnStyle, setWrnStyle] = useState(()=> {return {left: '0px', top: '0px'}});
  const [wrnFlag, setWrnFlag] = useState(false);

  //db interaction and global auth state --- later

  //collapse sidepanel button
  const toggleFunc = ()=>{ setCollapsePanel(prevState=>!prevState) }

  //set warning box style
  const wrnStyleFunc = useCallback((e, flag) => {x_wrnStyleFunc(e, flag, setWrnStyle)},[])
  
  //canvas size buttons
  const increaseWidthFunc = ()=> { setWidthCounter(prevCount => prevCount + 1) }
  const decreaseWidthFunc = ()=> { setWidthCounter(prevCount => (prevCount > 0)?(prevCount - 1):prevCount)}

  const increaseHeightFunc = ()=> { setHeightCounter(prevCount => prevCount + 1)}
  const decreaseHeightFunc = ()=> { setHeightCounter(prevCount => (prevCount > 0)?(prevCount - 1):prevCount)}

  let panelNdCanvasSize = useMemo( ()=> x_panelNdCanvasSizeFunc(collapsePanel, widthCounter, heightCounter),
   [collapsePanel, widthCounter, heightCounter]) 

  //to update the globalElements state with new element from side panel list  
  const globalElementsUpdater = useCallback( function (id, style){
    x_globalElementsUpdater(id, style, setGlobalElements)
  },[]) 

  const globalLanesUpdater = useCallback( function (id, style, x_setCounter){
    x_globalLanesUpdater(id, style, x_setCounter, setGlobalLanes)
  },[])

  //to modify the details of elements in the globalElements state after dragging , resizing ..etc
  const globalElementsModifier = useCallback( function (id, style){
    x_globalElementsModifier(id, style, setGlobalElements)
  },[])

  const globalLanesModifier = useCallback( function (id, style) {
    x_globalLanesModifier(id, style, setGlobalLanes)
  },[])

  const globalElementsPointsReset = useCallback( function (id){
    x_globalElementsPointsReset (id, setGlobalElements)
  },[])

  const globalElementsIdsReset = useCallback( function (cnctrId, isHd, elemId){
    x_globalElementsIdsReset (cnctrId, isHd, elemId, setGlobalElements)
  },[])

  const globalElementsModifier2Parent = useCallback(function (x_slctdIdList, x_delta){
    x_globalElementsModifier2(x_slctdIdList, x_delta, setGlobalElements)
  },[])

  //for warning box
  const wrnBoxHtml = useMemo(() => {return (
    wrnShow && <div className='wrnBox' style={wrnStyle}>
      <img src={`/project_svg/app_svg/${wrnFlag?'check_circle.svg':'cancel_circle.svg'}`} alt={wrnId} width="28px"/> 
      <img src={`/project_svg/listC_svg copy/${idSvgMap[wrnId]}.svg`} alt={wrnId} width="22px"/> 
      <span>{getTextById(wrnId)}</span></div>
  )}, [wrnShow, wrnId, wrnStyle, wrnFlag]) 

  return (
    <MyContextHd.Provider value={{toggleFunc}}>
      <MyContextSp.Provider value={{collapsePanel, toggleFunc, setWrnShow, setWrnId, wrnStyleFunc}}>
            <MyContextMn.Provider value={{
              setWrnFlag, globalElements,setGlobalElements, globalElementIdMaps, setGlobalElementIdMaps, globalElementTexts, setGlobalElementTexts, globalLanes, setGlobalLanes, slctdElemId, setSlctdElemId, 
              showDummy, setShowDummy, globalElementsUpdater, globalLanesUpdater, globalElementsModifier, globalLanesModifier, globalElementsPointsReset, globalElementsIdsReset, increaseWidthFunc, decreaseWidthFunc, 
              increaseHeightFunc, decreaseHeightFunc, slctdIdList, setSlctdIdList, showDummyList, setShowDummyList, globalElementsModifier2Parent}}>
              <div className="container" style={panelNdCanvasSize}>
                {wrnBoxHtml}
                {children}
              </div>                
            </MyContextMn.Provider>
        </MyContextSp.Provider>
    </MyContextHd.Provider>
  );
}

// mycontextHd needs value, toggle func removal

