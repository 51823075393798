import { wdtHtFnc, IsCnctrFunc, LineSvgParams, GlobalText, IsActivitsFunc, IsDataObjFunc, GlobalIdMap, idSvgMap, IsLaneFunc, isParentLaneFunc, isSingleLaneFunc, isChildLaneFunc} from "../data/stdClsFnc";
import { coordTraslationFunc, discretize10, discretize5, rectify, idConctrBelowFunc, findLaneHeight, separateLaneIds, findLaneLength, findLaneOrdinate } from "./Mn_CanvasCompUtil_func";

function afterDragCompleteParentFunc (x_slctdElemId, x_setShowDummy, x_newElemId, x_counter, x_dummyElementStyle, x_globalElementsUpdater, x_globalLanesUpdater, x_setSlctdElemId, 
    x_setTxtBxCpId, x_setCounter, x_IsConnector, x_IsLane, x_globalElementsModifierParent,  x_globalLanesModifierParent, xx_globalElementsIdsReset, x_setGlobalElementIdMaps, x_setResetPtSgnal) {

    function resetArrowsBelow ( elemId, resetBool) {
        let idConctrBelowFuncArray = idConctrBelowFunc ('.dobjct-copy', 'cnctnHead', 'cnctnTail');
        if (idConctrBelowFuncArray.length !== 0) {
            let cnctrId, isHd ;
            [cnctrId, isHd] = idConctrBelowFuncArray;
            let idxs = isHd?'idfs':'idis';
            // console.log(cnctrId, isHd, idxs, elemId);
            xx_globalElementsIdsReset(cnctrId, isHd, elemId);
            idMapSetterFunc (null,elemId, idxs, x_setGlobalElementIdMaps, cnctrId);
            resetBool && x_setResetPtSgnal(true);
        }
        (!resetBool) && x_setResetPtSgnal(true);
    }

    if (x_slctdElemId === 'newElement0') {x_setShowDummy(false);}
    else if (x_slctdElemId === 'newElement') {
        let elemId = idSvgMap[x_newElemId] + x_counter;
        x_globalElementsUpdater(elemId, x_dummyElementStyle);
        x_setCounter(prevCount=>prevCount+ Math.floor(Math.random() * 100));
        x_setSlctdElemId(elemId);
        x_setTxtBxCpId(elemId);
        // all arrows resetting initiates here 
        if (!(IsCnctrFunc(elemId)) )  { resetArrowsBelow(elemId, true);}
        else if (IsCnctrFunc(elemId)) {
            let { ids } = { ...x_dummyElementStyle };
            let idiToremove = null, idfToremove = null, idiToAdd = ids[0], idfToAdd = ids[1];
            idMapSetterFunc (idiToremove, idiToAdd, 'idis', x_setGlobalElementIdMaps, elemId);
            idMapSetterFunc (idfToremove, idfToAdd, 'idfs', x_setGlobalElementIdMaps, elemId);
        }
    }
    else if (x_slctdElemId.slice(0,7) === 'newLane') {
      if (x_slctdElemId.slice(0,8) === 'newLane0') {
        let elemId = idSvgMap[x_newElemId] + x_counter;
        x_globalLanesUpdater(elemId, x_dummyElementStyle, x_setCounter);
        x_setSlctdElemId(elemId);
        x_setTxtBxCpId(elemId);        
      } else if (x_slctdElemId.slice(0,8) === 'newLane1') {
        let idCombo = x_slctdElemId.slice(8);
        let { superId } = separateLaneIds(idCombo);
        idCombo = 'newLane1-' + x_counter + '-' + idCombo;
        x_globalLanesUpdater(idCombo, {}, x_setCounter);
        x_setShowDummy(false);
        // may be make the following ids null
        x_setSlctdElemId(superId);
        x_setTxtBxCpId(superId);
      }
    }
    else if (x_IsLane) { x_globalLanesModifierParent(); isSingleLaneFunc(x_slctdElemId) && x_setShowDummy(false)}
    else if (!x_IsConnector) {
      x_globalElementsModifierParent();            
      resetArrowsBelow(x_slctdElemId, false);
    }
}

function dummyStlUpdaterChildFunc(e, x_setIsCnctnHd, x_setIsCnctnTl, x_setIsCnctnBdy, x_setIsNonCnctnBdy, x_setIsNonCnctSwimLane, x_setIsNonCnctSingleLane, x_setIsSlctdLst, x_slctdElemId, x_setSlctdElemId, x_showIpBx, x_globalTextUpdaterParent,
    x_setShowIpBx, x_setTxtBxCpId, x_globalElements, x_setGlobalElements, x_globalLanes, x_setIsResize, x_setDummyElementStyle, x_showDummy, x_setShowDummy, x_setMdPositon,
    x_setSlctdIdList, x_showDummyList, x_setShowDummyList){
        
        const {classList} = e.target;
        const elemId = classList.contains("cnctnHead", "cnctnTail", "slctdLst") ? e.target.dataset.id : e.currentTarget.id;
        let IsLane = IsLaneFunc(elemId);
        let isSwimLane = isParentLaneFunc(elemId);
        let isChildLane = isChildLaneFunc(elemId);
        let isSingleLane = isSingleLaneFunc(elemId);

        //for text insertion for prev elem
        if (x_showIpBx) { x_globalTextUpdaterParent(); x_setShowIpBx(false); }
        x_setTxtBxCpId(elemId);
        //textinsertion code ends

        if (IsLane) { singleDummyUpdater(); }
        else if (x_showDummyList && (e.ctrlKey || e.metaKey)) { x_setSlctdIdList(prevArray => [...prevArray, elemId]); }
        else if (x_showDummy && (e.ctrlKey || e.metaKey)) { 
            if(IsLaneFunc(x_slctdElemId)){singleDummyUpdater();}
            else {
              x_setSlctdIdList ([x_slctdElemId, elemId]);
              x_setShowDummy(false);
              x_setShowDummyList(true);
            }            
        }
        else { singleDummyUpdater(); }

        function singleDummyUpdater() {
          if (x_showDummyList) { x_setShowDummyList(false); x_setSlctdIdList([]);}

          let posX = e.clientX, posY = e.clientY ;
          let newDumStyl, backgroundImage;
          
          if (IsLane) {      
            let left, top, width, height;       
            if (isSwimLane) {
              let {position, length } = x_globalLanes[elemId];
              let sLaneHeight = findLaneHeight(elemId, x_globalLanes);
              left = position[0] + 'px'; top = position[1] + 'px'; width = 20 + length + 'px'; height = sLaneHeight + 'px';                
            } else if (isSingleLane) {
              let { laneId, superId } = separateLaneIds(elemId);
              let {resultId: swimLaneId, resultCount} = findLaneLength(superId, x_globalLanes);
              let {height: laneHt} = x_globalLanes[laneId];
              let {position, length } = x_globalLanes[swimLaneId];
              length = length - (resultCount * 20);
              let abscissa = position[0] + ((resultCount + 1) * 20);
              let { x_bool, result} = findLaneOrdinate(swimLaneId,laneId, x_globalLanes);
              if (!x_bool) { console.error(`${laneId} not in ${swimLaneId}`); return;}
              left = abscissa + 'px'; top = result + 'px'; width = length + 'px'; height = laneHt + 'px';
            } else if (isChildLane) {
              let {resultId: swimLaneId, resultCount} = findLaneLength(elemId, x_globalLanes);
              let claneHt = findLaneHeight(elemId, x_globalLanes);
              let {position, length } = x_globalLanes[swimLaneId];
              length = length - ((resultCount - 1) * 20);
              let abscissa = position[0] + (resultCount * 20);
              let { x_bool, result} = findLaneOrdinate(swimLaneId,elemId, x_globalLanes);
              if (!x_bool) { console.error(`${elemId} not in ${swimLaneId}`); return;}
              left = abscissa + 'px'; top = result + 'px'; width = length + 'px'; height = claneHt + 'px';
            }
            newDumStyl = {left, top, width, height};
            console.log(newDumStyl);
          }
          else if (IsCnctrFunc(elemId)) {
            newDumStyl = x_globalElements[elemId].style;
            x_setGlobalElements((prevState) => {
              return { ...prevState, [elemId]: { ...prevState[elemId], IsRsz: false },
              };
            });
          } 
          else {
            ({ backgroundImage, ...newDumStyl } = x_globalElements[elemId].style);
            x_setIsResize(x_globalElements[elemId].IsRsz);
          }
          x_setDummyElementStyle(() => newDumStyl);
          x_setSlctdElemId(elemId);
          console.log(elemId);
          x_setMdPositon(() => {  return { x: posX, y: posY }; });
          x_setShowDummy(true);
          
          if (classList.contains("slctdLst")) { x_setIsSlctdLst(true); } 
          else if (classList.contains("cnctnHead")) { x_setIsCnctnHd(true); } 
          else if (classList.contains("cnctnTail")) { x_setIsCnctnTl(true); } 
          else if (isSwimLane) { x_setIsNonCnctSwimLane(true); }
          else if (isSingleLane || isChildLane) { const parts = elemId.split("-"); if (parts[2] === 'Resize') { x_setIsNonCnctSingleLane(true); }}
          else { IsCnctrFunc(elemId) ? x_setIsCnctnBdy(true) : x_setIsNonCnctnBdy(true) } //potential bug source for nonCnctnBdy
        }            
} 

function hdTlMsDnChildFunc (e, clbkSetState1, clbkSetState2, clbkSetState3) {
    e.stopPropagation(); e.preventDefault(); let posX = e.clientX, posY = e.clientY;
    if (e.target.classList.contains("cnctnHead")) {clbkSetState1(true);}
    else if (e.target.classList.contains("cnctnTail")) {clbkSetState2(true);}
    clbkSetState3(()=>{return {x:posX, y:posY}});
}

function x_adjustTxtAreaHt(x_textareaRef) {
  const ipTxtArea = x_textareaRef.current;
  ipTxtArea.style.height = 'auto';
  ipTxtArea.style.height = `${ipTxtArea.scrollHeight}px`;
}

function x_insertTextFunc(x_id, x_globalElements, x_globalElementTexts, x_globalLanes, x_setInputTxt, x_setInputStyle, x_setShowIpBx, x_textareaRef) {
  let IsLane = IsLaneFunc(x_id);
  let IsCnctr = IsCnctrFunc(x_id);
  let isTask = IsActivitsFunc(x_id) || IsDataObjFunc(x_id);
  
  if (IsLane) {
    if (isParentLaneFunc(x_id)) {
    let { title, position } = x_globalLanes[x_id];
    let laneHeight = findLaneHeight(x_id, x_globalLanes);
    x_setInputTxt(title);
    let left = position[0] - 40 + "px";
    let top = position[1] + laneHeight / 2 + "px";
    x_setInputStyle(() => ({ left, top }));
    } else if (isChildLaneFunc(x_id)) {
    let { resultId: swimLaneId, resultCount } = findLaneLength(x_id, x_globalLanes);
    let laneHeight = findLaneHeight(x_id, x_globalLanes);
    let { position } = x_globalLanes[swimLaneId];
    let { title } = x_globalLanes[x_id];
    x_setInputTxt(title);
    let { x_bool, result } = findLaneOrdinate(swimLaneId, x_id, x_globalLanes);
    if (!x_bool) { console.error(`${x_id} not in ${swimLaneId}`); return;}
    let left = position[0] + (resultCount * 20) - 40 + "px";
    let top = result + laneHeight / 2 + "px";
    x_setInputStyle(() => ({ left, top }));
    } else if (isSingleLaneFunc(x_id)) {
    let { laneId, superId } = separateLaneIds(x_id);
    let { resultId: swimLaneId, resultCount } = findLaneLength(superId, x_globalLanes);
    let { name, height: laneHeight } = x_globalLanes[laneId];
    x_setInputTxt(name);
    let { position } = x_globalLanes[swimLaneId];
    let { x_bool, result } = findLaneOrdinate(swimLaneId, laneId, x_globalLanes);
    if (!x_bool) { console.error(`${laneId} not in ${swimLaneId}`); return;}
    let left = position[0] + (resultCount * 20) - 20 + "px";
    let top = result + laneHeight / 2 + "px";
    x_setInputStyle(() => ({ left, top }));
}

  } else if (x_globalElementTexts[x_id]) {
    let { txt, txtStl } = x_globalElementTexts[x_id];
    x_setInputTxt(txt);
    if (IsCnctr) { x_setInputStyle({ ...txtStl });} 
    else if (isTask) {
      let { left, top } = x_globalElements[x_id].style;
      left = parseFloat(left) + 10 + "px";
      top = parseFloat(top) + 10 + "px";
      x_setInputStyle(() => { return { left, top };});}
    else { x_setInputStyle(() => coordTraslationFunc(true, txtStl, x_globalElements[x_id].style));}
  }
  else {
    // first time input style generation
    x_setInputTxt("");
    let left, top;
    if (IsCnctr) {
      let { pts } = x_globalElements[x_id].style;
      left = discretize5((9 * pts[0][0] + pts[1][0]) / 10 + 20) + "px";
      top = discretize5((9 * pts[0][1] + pts[1][1]) / 10 + 20) + "px";
    } 
    else if (isTask) { ({ left, top } = x_globalElements[x_id].style);}
    else {
      let width, height;
      ({ left, top, width, height } = x_globalElements[x_id].style);
      left =  parseFloat(left) + (parseFloat(width) / 2 - 50) + "px";
      top = parseFloat(top) + parseFloat(height) + 5 + "px";
    }
    x_setInputStyle(() => { return { left, top }; });
  }
  x_setShowIpBx(true);
  setTimeout(() => {
    x_textareaRef.current && x_textareaRef.current.focus();
  }, 200);
}

function x_createTxtBxCopy (e, x_setMdPositon, x_setTxtBxCpId, x_insertTextFunc, x_isDblClkRef, x_globalElementTexts, x_setStlCopy, 
    x_globalElements, x_setTxtCopy, x_setTxtBxCpShow, x_setGlobalElementTexts, x_showIpBx, x_globalTextUpdaterParent, x_setShowIpBx) {
      
    e.stopPropagation(); e.preventDefault();

    if (x_showIpBx) {
      x_globalTextUpdaterParent();
      x_setShowIpBx(false);
    };
      
    x_setMdPositon(() => { return { x: e.clientX, y: e.clientY } });
    let txtBxId = e.target.dataset.id;
    x_setTxtBxCpId(txtBxId);

    if (x_isDblClkRef.current) {
      x_insertTextFunc(txtBxId);
      x_isDblClkRef.current = false;
    } 
    else {
      x_isDblClkRef.current = true;
      setTimeout(() => { x_isDblClkRef.current = false; }, 500);
      let { txt, txtStl } = x_globalElementTexts[txtBxId];
      if (IsCnctrFunc(txtBxId)) {x_setStlCopy(txtStl);} 
      else { x_setStlCopy(() => coordTraslationFunc(true, txtStl, x_globalElements[txtBxId].style));}
      x_setTxtCopy(txt);
      x_setTxtBxCpShow(true);
      x_setGlobalElementTexts((prevTxtElem) => {
        return {...prevTxtElem, [txtBxId]: { ...prevTxtElem[txtBxId], show: false }};
      });
    }
}

function x_destroyTxtBxCopy (x_txtBxCpId, x_stlCopy, x_globalElements, x_setGlobalElementTexts, x_setTxtBxCpShow) {
  let newTxtStl;
  if (IsCnctrFunc(x_txtBxCpId)) { newTxtStl = { ...x_stlCopy };}
  else { newTxtStl = coordTraslationFunc( false, x_stlCopy, x_globalElements[x_txtBxCpId].style);}
  x_setGlobalElementTexts((prevTxtElem) => {
    return {...prevTxtElem, [x_txtBxCpId]: { ...prevTxtElem[x_txtBxCpId], txtStl: newTxtStl, show: true,},};
  });
  x_setTxtBxCpShow(false);
}

function createDummyFunc (e, x_setIsResize, x_setSlctdElemId, x_setDummyElementStyle, x_setShowDummy, x_setIsCnctnBdy, x_setIsNonCnctnBdy, 
  x_setIsNonCnctSwimLane, x_setMdPositon, x_setNewElemId, x_showDummyList, x_setShowDummyList, x_setSlctdIdList){

    let style, id = e.detail.id;
    let Resz = [10, 12, 13, 14].includes(Math.floor(id/10))? true : false;
    
    if (IsCnctrFunc(id.toString())){
        let x1 = discretize5(e.detail.X - 330), y1 = discretize5(e.detail.Y - 84);
        let x2 = discretize5(e.detail.X - 170), y2 = discretize5(e.detail.Y - 84);
        let ids = ['',''], pts = [[x1,y1],[x2,y2]];
        style = new LineSvgParams(ids, pts);
        x_setIsCnctnBdy(true);
    }
    
    else if (IsLaneFunc(id.toString())) {
      let left, top, width, height;
      width = wdtHtFnc(id).width;
      height = wdtHtFnc(id).height;
      left = discretize10(e.detail.X - 260) + 'px';
      top = discretize10(e.detail.Y - 84 - (Number(height.slice(0,-2)))/2) + 'px';
      style = {left, top, width, height};   
      x_setIsNonCnctSwimLane(true);  
  }
    
    else {
        let left, top, width, height;
        width = wdtHtFnc(id).width;
        height = wdtHtFnc(id).height;
        left = discretize10(e.detail.X - 250 - (Number(width.slice(0,-2)))/2) + 'px';
        top = discretize10(e.detail.Y - 84 - (Number(height.slice(0,-2)))/2) + 'px';
        style = {left, top, width, height};   
        x_setIsNonCnctnBdy(true);  
    }
    
    x_setSlctdElemId(id + 'newElement');
    x_setIsResize(Resz);
    x_setDummyElementStyle(()=>style);    
    x_setMdPositon(()=>{return {x:e.detail.X, y:e.detail.Y}});
    x_setNewElemId(e.detail.id);
    x_setShowDummy(true);
    if (x_showDummyList) { x_setShowDummyList(false); x_setSlctdIdList([]);}
} 

const globalTextUpdater = function (x_id, x_inputTxt, x_inputStyle, x_setGlobalElementTexts, x_globalElements, x_setGlobalLanes){
    let elemStl, globalTxt;
    let IsLane = IsLaneFunc(x_id);
    let IsCnctr = IsCnctrFunc(x_id);
    let isTask = IsActivitsFunc(x_id) || IsDataObjFunc(x_id);

    if (IsLane) {
      x_setGlobalLanes((prevState) => {
        // Create a new object to avoid mutating prevState
        const newState = { ...prevState };
        if (isParentLaneFunc(x_id) || isChildLaneFunc(x_id)) {
          newState[x_id] = { ...newState[x_id], title:x_inputTxt };
        } else if (isSingleLaneFunc(x_id)) {
          const parts = x_id.split("-");
          let slctLaneId = parts[0];
          newState[slctLaneId] = { ...newState[slctLaneId], name:x_inputTxt };
        }
        // Return the updated state
        return newState;
      });
    } else if (x_inputTxt) {
        if (IsCnctr || isTask){
            elemStl = {...x_inputStyle}; //task elemstl is not needed for diplay of input box or text box
        }
        else {
            elemStl = coordTraslationFunc(false, x_inputStyle, x_globalElements[x_id].style);
        }
        globalTxt = new GlobalText(x_inputTxt, elemStl, true);
        x_setGlobalElementTexts((prevState)=>{return {...prevState, [x_id] : globalTxt}});
    }
    else {
        x_setGlobalElementTexts((prevState)=>{
            let newGlobalElemTxts = {...prevState};
            if (newGlobalElemTxts[x_id]) {delete newGlobalElemTxts[x_id]}
            return newGlobalElemTxts
        })
    }
  }

function x_deleteDummy(e, x_globalElementsModifierParent, x_globalTextUpdaterParent, x_showDummy, x_setShowDummy, x_setSlctdElemId, 
    x_showIpBx, x_setShowIpBx, x_IsConnector, x_showDummyList, x_setShowDummyList, x_setSlctdIdList){
    // console.log(`x_deleteDummy func start\n e.target ${e.target.className}`);
    e.stopPropagation(); e.preventDefault();
    if (x_showDummy){
        x_setShowDummy(false);
        x_IsConnector && x_globalElementsModifierParent();
        x_setSlctdElemId("");
    }
    if (x_showIpBx) { x_globalTextUpdaterParent(); x_setShowIpBx(false); };
    if (x_showDummyList) { x_setShowDummyList(false); x_setSlctdIdList([]);};
    // console.log("x_deleteDummy func stop");
}     

const resetAllPointsFunc = (globalMap, objId, calbk) => {
    if (globalMap && globalMap[objId]) {
    for (let idxs of ['idis','idfs']) {
            for (let x_id of globalMap[objId][idxs]) { calbk(x_id);}
}}}

// to update the globalElementsIdMap 
function idMapSetterFunc (idToremove, idToAdd, idxs, setglobalMap, slctdId) {

    if (idToremove !== idToAdd) {
        let newIdmap, newIdxsArr;
        if (idToremove) {
            setglobalMap((prevState) => {
                if (prevState[idToremove]) {
                    newIdmap = {...prevState[idToremove]};
                    newIdxsArr = newIdmap[idxs].filter(ids => ids !== slctdId);
                    newIdmap[idxs] = newIdxsArr;       
                    return {...prevState, [idToremove] : newIdmap}
                }                
            })
        }
        if (idToAdd) {
            setglobalMap((prevState) => {
                if (prevState) {
                    if (prevState[idToAdd]) {
                        newIdmap = {...prevState[idToAdd]};
                    }
                    else { newIdmap = new GlobalIdMap([],[]); }
                }
                else { newIdmap = new GlobalIdMap([],[]); }
                if (newIdmap[idxs].includes(slctdId)) { return prevState } //needed for reset arrows below
                else {
                    newIdxsArr = [...newIdmap[idxs], slctdId];
                    newIdmap[idxs] = newIdxsArr;
                    return {...prevState, [idToAdd] : newIdmap}
                }                
})}}}

//   drag function of txtBxCopy
function funcDrag(e, x_stlCopy, x_setStlCopy) {

    let startX = e.clientX ;
    let startY = e.clientY ;
    let initParams = {left: x_stlCopy['left'], top: x_stlCopy['top']};

    window.addEventListener("mousemove", funcMouseMove);
    window.addEventListener("mouseup", funcMouseUp);

    function funcMouseMove(e){
        let moveX = e.clientX - startX;
        let moveY = e.clientY - startY;

        x_setStlCopy(()=>{ return {
            left: rectify(Number(initParams['left'].slice(0,-2))+moveX)+'px',
            top: rectify(Number(initParams['top'].slice(0,-2))+moveY)+'px'
        }})      
    }

    function funcMouseUp(){        
        window.removeEventListener("mousemove", funcMouseMove);
        window.removeEventListener("mouseup", funcMouseUp);          
    }
}

function funcDrag2 (e, x_setStlCopy, clbkFunc1, clbkFunc2, x_slctdIdList, x_setSlctdIdList, x_globalElements, x_setGlobalElements, 
  x_setIsResize, x_setDummyElementStyle, x_setSlctdElemId, x_setShowDummy, x_setShowDummyList) {

  let startX = e.clientX, startY = e.clientY ;
  let moveX, moveY;

  window.addEventListener("mousemove", funcMouseMove);
  window.addEventListener("mouseup", funcMouseUp);

  function funcMouseMove(e){
      moveX = e.clientX - startX; moveX = discretize10(moveX);
      moveY = e.clientY - startY; moveY = discretize10(moveY);

      x_setStlCopy(()=>{ return { delX: moveX, delY: moveY }});   
  }

  function funcMouseUp(){        
      window.removeEventListener("mousemove", funcMouseMove);
      window.removeEventListener("mouseup", funcMouseUp);
      if ((Math.abs(moveX) > 5) || (Math.abs(moveY) > 5)) {
        clbkFunc1(x_slctdIdList, {delX: moveX, delY: moveY});
      }
      else {
        if (e.ctrlKey) { 
          console.log('control key pressed');
          let elemId = e.target.dataset.id;
          if (x_slctdIdList.length < 3) {
            console.log("list lenght 2");
            let dummyId = x_slctdIdList.find(item => item !== elemId);
            let newDumStyl, backgroundImage;
            if (IsCnctrFunc(dummyId)) {
              newDumStyl = x_globalElements[dummyId].style;
              x_setGlobalElements((prevState) => {
                return { ...prevState, [dummyId]: { ...prevState[dummyId], IsRsz: false },
                };
              });
            } 
            else {
              ({ backgroundImage, ...newDumStyl } = x_globalElements[dummyId].style);
              x_setIsResize(x_globalElements[dummyId].IsRsz);
            }
            x_setDummyElementStyle(() => newDumStyl);
            x_setSlctdElemId(dummyId);
            x_setShowDummy(true);
            x_setShowDummyList(false); 
            x_setSlctdIdList([]);
          }
          else if (x_slctdIdList.length > 2){
            console.log("list length greater than 2");
            x_setSlctdIdList(prevArray => prevArray.filter(item => item !== elemId))         
          }
        }
        else {console.log('control key NOT pressed'); clbkFunc2(e);}
      }
      x_setStlCopy(()=>{ return { delX: 0, delY: 0 }}); 
  }
}

export {afterDragCompleteParentFunc, dummyStlUpdaterChildFunc, hdTlMsDnChildFunc, x_adjustTxtAreaHt, x_insertTextFunc, x_createTxtBxCopy, x_destroyTxtBxCopy,
  createDummyFunc, globalTextUpdater, x_deleteDummy, resetAllPointsFunc, idMapSetterFunc, funcDrag, funcDrag2}

// this is how we target the canvas elements 
// - e.target.classList.contains('dobjct'), - e.target.className.baseVal === 'checker', - e.target.id==='connectors'