import {  useMemo } from "react";
import { hdGenFunc, TlGenFunc, calcAngleDegrees } from "./Mn_SingleConnector_func";


const SingleConnector = function ({id, line, handleMouseDown, handleMouseUp}){

        const cnctrType = id=>Number(id.slice(2,3));
        let typ = (cnctrType(id));
        // typ = { 0 : "Sequence Flow", 1 : "Association (Undirected)", 2 : "Association (Unidirectional)", 3 : "Message Flow", 4 : "Association (Bidirectional)" }

        let points = useMemo(() => [...line['pts']],[line]);

        let len = points.length;
        let [xi,yi] = points[0], [xf, yf] = points[len-1];

        let head = hdGenFunc(typ, points[len-1]);
        let tail = TlGenFunc(points[0]);

        let angl1 = calcAngleDegrees(points[0],points[1]);
        let angl2 = calcAngleDegrees(points[len-2],points[len-1]);
        let trnsfrm1 =`rotate(${angl1}, ${xi}, ${yi})`;
        let trnsfrm2 =`rotate(${angl2}, ${xf}, ${yf})`;

        let pointsA, pointsB;// pointsA for point insertion, B for text insertion
        if (len <= 3) {
            let xc = (2*(points[0][0]) + points[1][0])/3;
            let yc = (2*(points[0][1]) + points[1][1])/3;
            pointsA = [[xc,yc],...points.slice(1)];
            pointsB = [points[0],[xc,yc]];
        }
        else {
            pointsA = [...points.slice(1)];
            pointsB = [points[0],points[1]];
        }

        return (
        <g id = {id} className="dobjct-connector" onMouseDown={handleMouseDown} onMouseUp={handleMouseUp}>            
            {/* main line */}
            <polyline className="checker" points={points} fill="none" stroke="black" strokeDasharray={typ!==0?"3,4":'none'} strokeWidth="1.5" vectorEffect="non-scaling-stroke"/>
            {/* white line */}
            <polyline className="checker" points={pointsA} fill="none" stroke="transparent" strokeOpacity="0.3" strokeWidth="10" vectorEffect="non-scaling-stroke"/>
            {/* big circles for area , head and tail */}
            <circle className="checker" r="10" cx={xf} cy={yf} fill="transparent" fillOpacity="0.1" stroke="gray" strokeWidth="0" vectorEffect="non-scaling-stroke"/>
            <circle className="checker" r="10" cx={xi} cy={yi} fill="transparent" fillOpacity="0.1" stroke="gray" strokeWidth="0" vectorEffect="non-scaling-stroke"/>
            {/* arrow head and tail */}
            {typ!==1 && <polyline className="checker" points={head} transform={trnsfrm2} fill={typ===0?"black":(typ===3?"white":"none")} stroke="black" strokeWidth="1.5" vectorEffect="non-scaling-stroke"/>}
            {typ===4 && <polyline className="checker" points={tail} transform={trnsfrm1} fill="none" stroke="black" strokeWidth="1.5" vectorEffect="non-scaling-stroke"/>}
            {typ===3 && <circle className="checker" r="5" cx={xi -3 } cy={yi} transform={trnsfrm1} fill="white" stroke="black" strokeWidth="1" vectorEffect="non-scaling-stroke"/>}
            {/* for textBox  ****to be checked the disadvantages of both classes***/}
            <polyline className="checker" points={pointsB} fill="none" stroke="transparent" strokeOpacity="0.3" strokeWidth="10" vectorEffect="non-scaling-stroke"/>            
        </g>
    )}

export default SingleConnector;