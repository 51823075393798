import { isChildLaneFunc, isParentLaneFunc, isSingleLaneFunc } from "../data/stdClsFnc";

function mouseDnStimulate (trgtElem, coordnt) {        
    // console.log("mouseDnStimulate start");        
    if(trgtElem){
        const event = new MouseEvent("mousedown", {
            view: window,
            bubbles: true,
            cancelable: true,
            clientX: coordnt.x,
            clientY: coordnt.y,
        });
        trgtElem.dispatchEvent(event);
    }
    // console.log(`mouseDnStimulate stop\n ref elem : ${trgtElem}`);
}

function mouseDnStimulateParent (refElem, coord, clBkFunc) {
    mouseDnStimulate(refElem, coord);
    clBkFunc(false);
}

// to trigger custom event after drag finish
function drgFnshFunc (trgtElem) {        
    // console.log("drgFnsh start");        
    const drgFnshEvent = new CustomEvent("drgFnsh", {detail : "to run x_globalElementsModifierParent after drag"});
    trgtElem.dispatchEvent(drgFnshEvent);
    // console.log(`drgFnsh event trigger func ran stop\n ref elem : ${trgtElem}`);
}

function discretize10 (input) {
    return Math.round(input/10)*10;
}

function discretize5 (input) {
    input *= 2;
    let result = Math.round(input/10)*10;
    return result/2
}

function rectify (input) {
    if (input > 0) { return input }
    else return -10
}

function rectifySize (input) {
    if (input < 40) { return 40 }
    if (input > 20 && input < 200) { return input }
    else return 200
}

function rectifyLaneWd (input) {
  if (input < 400) { return 400 }
  if (input > 380 && input < 2000) { return input }
  else return 2000
}

function rectifyLaneHt (input) {
  if (input < 200) { return 200 }
  if (input > 180 && input < 2000) { return input }
  else return 2000
}

function idConctrBelowFunc (draggedBoxClas, hdClas, tlClas) {
  let result = [];
  let draggedBox = document.querySelector(draggedBoxClas)
  if (draggedBox) {
    let draggedBoxRect = draggedBox.getBoundingClientRect();

    let hdElems = document.getElementsByClassName(hdClas);
    let tlElems = document.getElementsByClassName(tlClas);
      
    for (let arrowHd of hdElems) {  
        const hdRect = arrowHd.getBoundingClientRect();          
        if(isColliding(draggedBoxRect, hdRect)){
          result = [arrowHd.dataset.id, true];
          break;
        }
    }
    for (let arrowTl of tlElems) {  
      const tlRect = arrowTl.getBoundingClientRect();          
      if(isColliding(draggedBoxRect, tlRect)){
        result = [arrowTl.dataset.id, false]
        break;
      }
    }
  }

  return result;        
}

function isInvalidFunc (draggingBox, slctdId, trgtclas) {
    let result = false;
    let draggingBoxRect = draggingBox.getBoundingClientRect();

    let targetAreas = document.getElementsByClassName(trgtclas)
    if (slctdId !== 'newElement') {
        targetAreas = Array.from(targetAreas).filter((elem) => elem.id !== slctdId);
    }
      
    for (let trgtelem of targetAreas) {  
        const targetAreaRect = trgtelem.getBoundingClientRect();          
        if(isColliding(draggingBoxRect, targetAreaRect)){
            result = true;
            break;
        }
    }
    return result;        
  }

function isColliding(rect1, rect2) {
    return (
      rect1.left < rect2.right &&
      rect1.right > rect2.left &&
      rect1.top < rect2.bottom &&
      rect1.bottom > rect2.top
    );
  }       

function idUnderCursor(e, clsNm) {
    const x = e.clientX;
    const y = e.clientY;
    return idUnderCoord(x, y, clsNm)
  }

function idUnderPoint(pt, clsNm) {
    const x = pt[0] + 250;
    const y = pt[1] + 84;
    return idUnderCoord(x, y, clsNm)
  }

function idUnderCoord(x, y, clsNm) {
    const elements = document.elementsFromPoint(x, y);
  
    for (const element of elements) {
      if (element.classList.contains(clsNm)) {
        return element.id;
      }
    }  
    return null;
  }

function isIdUnderCoord(x, y, clsNm) {
    const elements = document.elementsFromPoint(x, y);
  
    for (const element of elements) {
      if (element.classList.contains(clsNm)) {
        return true;
      }
    }  
    return false;
  }

  const coordTraslationFunc = (isAdd, obj1, obj2) =>{
    let {left: left_c, top: top_c} = obj1;
    let {left: left_p, top: top_p} = obj2;
    let left = (Number(left_c.slice(0,-2))+ ((isAdd?(+1):(-1))*(Number(left_p.slice(0,-2))))) + 'px';
    let top = (Number(top_c.slice(0,-2))+ ((isAdd?(+1):(-1))*(Number(top_p.slice(0,-2))))) + 'px';
    return ({left, top});     
}

const laneDummyStylFunc1 = (styleObj)=>{
  let {left, top, height} = styleObj;
  return {...styleObj, 
    left: parseFloat(left)-4+'px',
    top: parseFloat(top)-4+'px',
    width: '22px',
    height: parseFloat(height)+8+'px'
  }
}

const laneDummyStylFunc2 = (styleObj)=>{
  let {left, top, width, height} = styleObj;
  return {...styleObj, 
    left: parseFloat(left)+22+'px',
    top: parseFloat(top)-4+'px',
    width: parseFloat(width)-18+'px',
    height: parseFloat(height)+8+'px'
  }
}

const laneDummyStylFunc3 = (styleObj)=>{
  let {left, top, width, height} = styleObj;
  return {...styleObj, 
    left: parseFloat(left)-4+'px',
    top: parseFloat(top)-4+'px',
    width: parseFloat(width)+8+'px',
    height: parseFloat(height)+8+'px'
  }
}

const dobjctStylFunc1 = (styleObj)=>{
  let newStyleObj, backgroundImage;
  ({ backgroundImage, ...newStyleObj } = styleObj);
  return newStyleObj;
}

const dobjctStylFunc2 = (styleObj)=>{
  let { width, height, backgroundImage} = styleObj;
  return {
    // width: parseFloat(width)-10+'px',
    // height: parseFloat(height)-10+'px', *** to be done later dobjct svgs
    width: parseFloat(width)+'px',
    height: parseFloat(height)+'px',
    backgroundImage: backgroundImage
  }
}

function findLaneHeight(x_id, laneObject) {
  // Set to keep track of visited nodes
  const visited = new Set();
  
  // Recursive function to traverse the structure
  const traverse = (id) => {
    // If the node has been visited before, return 0 to avoid infinite recursion
    if (visited.has(id)) {
      return 0;
    }
    
    // Mark the current node as visited
    visited.add(id);
    
    // If the node is a lane, return its height
    if (laneObject[id]?.height !== undefined) {
      return laneObject[id].height;
    }
    
    // If the node is a swimlane, recursively sum the heights of its child lanes
    let height = 0;
    const laneIds = laneObject[id]?.laneIds || [];
    for (const subId of laneIds) {
      height += traverse(subId);
    }
    return height;
  };

  // Start traversal from the given id
  return traverse(x_id);
}

function findParentId(targetId, globalLanes) {
  // Validate if the targetId is not expected to be a parent itself
  if (isParentLaneFunc(targetId)) {
    console.error(`${targetId} is itself a parent swim lane and cannot have a parent.`);
    return null;
  }

  // Iterate over all lanes to find the parent of targetId
  for (const [parentId, lane] of Object.entries(globalLanes)) {
    if (!isSingleLaneFunc(parentId) && lane.laneIds.includes(targetId)) {
      // Found the parent lane of targetId
      return parentId;
    }
  }

  // Log an error if no parent was found for targetId
  console.error(`Parent not found for ${targetId}.`);
  return null;
}

function findLaneLength(targetId, globalLanes) {
  // Directly return for parent lanes
  if (isParentLaneFunc(targetId)) {
    return { resultId: targetId, resultCount: 0 };
  }

  // Define a helper function to avoid redefining it in each iteration
  function findMatch(x_id, depth) {
    const { laneIds } = globalLanes[x_id];
    for (const childId of laneIds) {
      if (childId === targetId) {
        // Found the target; return current depth
        return { found: true, depth };
      } else if (isChildLaneFunc(childId)) {
        // Recurse into children, incrementing depth
        const result = findMatch(childId, depth + 1);
        if (result.found) {
          return result;
        }
      }
    }
    return { found: false };
  }

  // Iterate over all parent swimlanes
  const swimIdsArray = Object.keys(globalLanes).filter(x_id => isParentLaneFunc(x_id));
  for (const swimId of swimIdsArray) {
    const result = findMatch(swimId, 1);
    if (result.found) {
      // Return the first parent found and the depth
      return { resultId: swimId, resultCount: result.depth };
    }
  }

  console.error(`Invalid lane id ${targetId} in findLaneLength function`);
  return null;
}

function separateLaneIds(id) {
  // Check if the provided id is a non-empty string
  if (typeof id !== 'string' || id.trim() === '') {
      console.error('Invalid id provided:', id);
      return null; // Return null if id is invalid
  }

  // Split the id string into parts using the hyphen delimiter
  const parts = id.split("-");
  const len = parts.length;

  let laneId, superId;

  // Determine the laneId and superId based on the number of parts
  if (len === 1) {
      laneId = parts[0];
      superId = parts[0];
  } else if (len >= 2) {
      laneId = parts[0];
      superId = parts[1];
  } else {
      laneId = null;
      superId = null;
  }

  // Return an object containing the laneId and superId
  return { laneId, superId };
}

function findLaneOrdinate(parentLaneId, targetId, laneObject, defaultValue) {
  let ordinate;

  if (isParentLaneFunc(parentLaneId)) {
      ordinate = laneObject[parentLaneId].position[1];
  } else if (isChildLaneFunc(parentLaneId) && defaultValue !== undefined) {
      ordinate = defaultValue;
  } else {
      console.error(`Invalid parent lane id: ${parentLaneId}`);
      return null;
  }

  const laneIds = laneObject[parentLaneId].laneIds;

  for (const x_id of laneIds) {
      if (isSingleLaneFunc(x_id)) {
          if (x_id === targetId) { //if target is singlelane
              return { x_bool: true, result: ordinate };
          } else {
              ordinate += laneObject[x_id].height;
          }
      } else if (isChildLaneFunc(x_id)) {
          if (x_id === targetId) { //if target is childlane
              return { x_bool: true, result: ordinate };
          }
          const { x_bool, result } = findLaneOrdinate(x_id, targetId, laneObject, ordinate);
          if (x_bool) {
              return { x_bool, result };
          } else {
              // Only add to ordinate if the target wasn't found in this branch,
              // ensuring we don't double-count the starting ordinate of this child lane.
              ordinate = result;
          }
      }
  }

  return { x_bool: false, result: ordinate };
}

function addHighlightClass (x_bool, x_id) {
  let workingId = adjustId(x_id)
  const currentElement = workingId ? document.getElementById(workingId) : null;
  if (currentElement) {
      currentElement.classList.toggle("highlightClass", x_bool) ; 
  }
};

function addVanishClass (x_bool, x_id) {
  const currentElements = document.getElementsByClassName(x_id);
  for (let currentElement of currentElements) {
    currentElement.classList.toggle("vanishClass", x_bool) ;
  }   
};

function adjustId(x_id) {
  // Check for non-empty input
  if (!x_id || x_id.trim() === '') {
    return x_id; // Return as is for empty or whitespace-only strings
  }

  // Check if the ID ends with '-Body'
  if (x_id.endsWith('-Body')) {
    // Replace '-Body' with '-Left' and append '-Hlt'
    return x_id.replace('-Body', '-Left') + '-Hlt';
  } else {
    // For IDs not ending with '-Body', simply append '-Hlt'
    return x_id + '-Hlt';
  }
}

function findAdjSingleLane (x_id, isFirst, laneObject) {
  if (isSingleLaneFunc(x_id)) { return x_id }
  else if (isChildLaneFunc(x_id)) {
    let {laneIds} = laneObject[x_id];
    if (isFirst) {
      return findAdjSingleLane(laneIds[0], true, laneObject);      
    } else {
      let modLaneIds = laneIds.slice(-1);
      return findAdjSingleLane(modLaneIds[0], false, laneObject);
    }
  } else { 
    console.error(`invalid id ${x_id}`);
    return null 
  }
}

function findDeleteIds(swimId, laneObject) {
  let delIds = [];

  function traverseIds(x_id) {
    delIds.push(x_id);
    let { laneIds } = laneObject[x_id];
    laneIds.forEach(laneId => {
      if (isSingleLaneFunc(laneId)) {
        delIds.push(laneId);
      } else if (isChildLaneFunc(laneId)) {
        traverseIds(laneId);
      } else {
        console.error(`Invalid id ${laneId}`);
      }
    });
  }

  traverseIds(swimId);
  return delIds;
}



export {mouseDnStimulate, mouseDnStimulateParent, drgFnshFunc,discretize10, discretize5, rectify, rectifyLaneHt, rectifyLaneWd, 
  rectifySize, idConctrBelowFunc, isInvalidFunc, idUnderCursor, idUnderPoint, isIdUnderCoord, coordTraslationFunc,
  laneDummyStylFunc1, laneDummyStylFunc2, laneDummyStylFunc3, dobjctStylFunc1, dobjctStylFunc2, findLaneHeight, findParentId, findLaneLength, separateLaneIds,
  findLaneOrdinate, addHighlightClass, addVanishClass, adjustId, findAdjSingleLane, findDeleteIds
}

// this is how we target the canvas elements 
// - e.target.classList.contains('dobjct'), - e.target.className.baseVal === 'checker', - e.target.id==='connectors'