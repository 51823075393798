import { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import SingleConnector from './Mn_SingleConnector';
import { IsActivitsFunc, IsCnctrFunc, IsLaneFunc, IsDataObjFunc, isParentLaneFunc, dummyClassCreator, isSingleLaneFunc, isChildLaneFunc } from '../data/stdClsFnc';
import { dragResizeFunction1 } from './Mn_drag_resize1';
import { dragResizeFunction2 } from './Mn_drag_resize2';
import { dragResizeFunction3 } from './Mn_drag_resize3';
import { useMyContextMn } from '../../Master';
import { afterDragCompleteParentFunc, dummyStlUpdaterChildFunc, hdTlMsDnChildFunc, x_adjustTxtAreaHt, x_insertTextFunc, x_createTxtBxCopy,
    x_destroyTxtBxCopy, createDummyFunc, globalTextUpdater, x_deleteDummy,resetAllPointsFunc, funcDrag} from './Mn_CanvasComp_func';
import { mouseDnStimulate, drgFnshFunc, laneDummyStylFunc1, laneDummyStylFunc2, laneDummyStylFunc3, dobjctStylFunc1, dobjctStylFunc2 } from './Mn_CanvasCompUtil_func';
import ConnectorHdTl from './Mn_ConnectorHdTl';
import CanvasSize from './Mn_CanvasSize';
import SelectedList from './Mn_SelectedList';
import SLaneAnchorComp from './Mn_SLaneAnchorComp';
import SLane from './Mn_SLane';
import { dragResizeFunction4 } from './Mn_drag_resize4';
import SingleConnectorDummy from './Mn_SingleConnectorDummy';


export default function CanvasComp() {

    const {setWrnFlag, globalElements, setGlobalElements, globalElementIdMaps, setGlobalElementIdMaps, globalElementTexts, setGlobalElementTexts, 
        slctdElemId, setSlctdElemId, showDummy, setShowDummy, globalElementsUpdater, globalLanesUpdater, globalElementsModifier, globalLanesModifier, globalElementsPointsReset,
        globalElementsIdsReset, setSlctdIdList, showDummyList, setShowDummyList, globalLanes, setGlobalLanes} = useMyContextMn()

    const [dummyElementStyle, setDummyElementStyle] = useState({});
    const [newElemId, setNewElemId] = useState('');
    const [isResize, setIsResize] = useState(false);
    const [mdPosition, setMdPositon] = useState({x:0, y:0}); 
    const dumRef = useRef(null);
    const dumSwimLaneRef = useRef(null);
    const dumSingleLaneRef = useRef(null);
    const IsConnector = IsCnctrFunc(slctdElemId);
    const IsLane = IsLaneFunc(slctdElemId);
    const isSwimLane = isParentLaneFunc(slctdElemId);
    const isChildLane = isChildLaneFunc(slctdElemId);
    let isSingleLaneRsz = false, isSingleLaneLft = false
    if (isSingleLaneFunc(slctdElemId) ) {
        const parts = slctdElemId.split("-"); 
        if (parts[2] === 'Left') { isSingleLaneLft = true; }
        else if (parts[2] === 'Resize') { isSingleLaneRsz = true; }
    }
    const [drgFnsh, setDrgFnsh] = useState(0);
    const [counter, setCounter] = useState(()=>10000+Math.floor(Math.random() * 100));
    const [resetPtSgnal, setResetPtSgnal] = useState(false);
    const [isClsInvld, setClsInvld] = useState(false);//box red on overlap

    const [isCnctnHd, setIsCnctnHd] = useState(false);
    const [isCnctnTl, setIsCnctnTl] = useState(false);
    const [isCnctnBdy, setIsCnctnBdy] = useState(false);
    const [isNonCnctnBdy, setIsNonCnctnBdy] = useState(false);
    const [isNonCnctSwimLane, setIsNonCnctSwimLane] = useState(false);
    const [isNonCnctSingleLane, setIsNonCnctSingleLane] = useState(false);
    const [isSlctdLst, setIsSlctdLst] = useState(false);
    const cnctnRef = useRef(null);


    // input Box
    const textareaRef = useRef(null);
    const [inputTxt, setInputTxt] = useState('');
    const [inputStyle, setInputStyle] = useState({});
    const isDblClkRef = useRef(false);
    const [showIpBx, setShowIpBx] = useState(false);
    // textBox copy
    const txtBxCpRef = useRef(null);
    const [txtCopy, setTxtCopy] = useState('');
    const [stlCopy, setStlCopy] = useState({});
    const [txtBxCpId, setTxtBxCpId] = useState('');
    const [txtBxCpShow, setTxtBxCpShow] = useState(false);

    //to finally set global state from dummy element 
    const globalElementsModifierParent = useCallback(()=>{
        globalElementsModifier(slctdElemId, dummyElementStyle);
    },[globalElementsModifier, slctdElemId, dummyElementStyle])

    const globalLanesModifierParent = useCallback(()=>{
        globalLanesModifier(slctdElemId, dummyElementStyle);
    },[globalLanesModifier, slctdElemId, dummyElementStyle])

    const globalTextUpdaterParent = useCallback(() => {
        globalTextUpdater(txtBxCpId, inputTxt, inputStyle, setGlobalElementTexts, globalElements, setGlobalLanes);
    },[globalElements, inputTxt, inputStyle, txtBxCpId, setGlobalElementTexts, setGlobalLanes])
    
    const afterDragCompleteFunc = useCallback(()=>{
        // console.log('afterDragCompleteFunc start');
        afterDragCompleteParentFunc (slctdElemId, setShowDummy, newElemId, counter, dummyElementStyle, globalElementsUpdater, globalLanesUpdater,
            setSlctdElemId, setTxtBxCpId, setCounter, IsConnector, IsLane, globalElementsModifierParent, globalLanesModifierParent, globalElementsIdsReset, setGlobalElementIdMaps, setResetPtSgnal)
        // console.log('afterDragCompleteFunc stop');
    }
    , [slctdElemId, setShowDummy, setSlctdElemId, newElemId, counter, dummyElementStyle, globalElementsUpdater, globalLanesUpdater, IsConnector, IsLane, globalElementsModifierParent, globalLanesModifierParent, globalElementsIdsReset, setGlobalElementIdMaps])
    
    // handle newElemEvent
    const handleNewElemEvent = useCallback(function (e) {
        // console.log('handleNewElemEvent start');
        e.stopPropagation(); e.preventDefault();
        (showDummy && IsConnector) && globalElementsModifierParent();
        createDummyFunc (e, setIsResize, setSlctdElemId, setDummyElementStyle, setShowDummy, setIsCnctnBdy, setIsNonCnctnBdy, 
            setIsNonCnctSwimLane, setMdPositon, setNewElemId, showDummyList, setShowDummyList, setSlctdIdList);
        // console.log('handleNewElemEvent stop');
    },[showDummy, setShowDummy, setSlctdElemId, IsConnector, globalElementsModifierParent, showDummyList, setShowDummyList, setSlctdIdList])

    // to update connector points if connected to objects
    useEffect(()=>{
        if (resetPtSgnal) {
            resetAllPointsFunc(globalElementIdMaps, slctdElemId, globalElementsPointsReset);
            setResetPtSgnal(false);
        }
    },[globalElementIdMaps, slctdElemId, globalElementsPointsReset, resetPtSgnal, globalElements])

    // to listen custom event after drag
    useEffect(()=>{
        document.addEventListener("drgFnsh", afterDragCompleteFunc);
        return (()=>{document.removeEventListener("drgFnsh", afterDragCompleteFunc)})    
    },[afterDragCompleteFunc])
    //to listen to new element being brought to canvas
    useEffect(()=>{
        document.addEventListener("newElem", handleNewElemEvent);
        return (()=>{document.removeEventListener("newElem", handleNewElemEvent)})
    },[handleNewElemEvent])

    //to copy the details of the selected element on canvas so as to send it to dummy element..IsCnctrFunc(elemId)?sequencingFunction().then..*** later use may be
    const dummyStlUpdaterFunc = useCallback( function (e){
        // console.log("dummyStlUpdaterFunc start");
        e.stopPropagation(); e.preventDefault(); 
        (showDummy && IsConnector) && globalElementsModifierParent();
        dummyStlUpdaterChildFunc(e, setIsCnctnHd, setIsCnctnTl, setIsCnctnBdy, setIsNonCnctnBdy, setIsNonCnctSwimLane, setIsNonCnctSingleLane, setIsSlctdLst, slctdElemId, setSlctdElemId, showIpBx, globalTextUpdaterParent,
            setShowIpBx, setTxtBxCpId, globalElements, setGlobalElements, globalLanes, setIsResize, setDummyElementStyle, showDummy, setShowDummy, setMdPositon,
            setSlctdIdList, showDummyList, setShowDummyList);  
        // console.log("dummyStlUpdaterFunc stop");
    }, [IsConnector, slctdElemId, setSlctdElemId, setShowDummy, globalElements, setGlobalElements, globalLanes, showDummy, showIpBx, globalElementsModifierParent, 
        globalTextUpdaterParent, setSlctdIdList, showDummyList, setShowDummyList])   

    //to send the mousedown from the hdtl div element to hdtl singleConnector comp 
    const hdTlMsDnFunc = useCallback( function(e) {    
        hdTlMsDnChildFunc(e, setIsCnctnHd, setIsCnctnTl, setMdPositon);
    }, [])

    // to stimulate second mouse down
    useEffect(() => {
        console.log(" mousedn event effect start");
        if (isSlctdLst) { setIsSlctdLst(false);}
        else if (isCnctnHd) { cnctnRef.current.hdMsDn(mdPosition, setIsCnctnHd)}
        else if (isCnctnTl) { cnctnRef.current.tlMsDn(mdPosition, setIsCnctnTl)}
        else if (isCnctnBdy) { cnctnRef.current.bdyMsDn(mdPosition, setIsCnctnBdy)}
        else if (isNonCnctnBdy) {
            let refElem = dumRef.current; 
            if (!txtBxCpShow && refElem) { mouseDnStimulate(refElem, mdPosition); setIsNonCnctnBdy(false);}
        }
        else if (isNonCnctSwimLane) {
            let refElem = dumSwimLaneRef.current; 
            // console.log(refElem);
            if (!txtBxCpShow && refElem) { mouseDnStimulate(refElem, mdPosition); setIsNonCnctSwimLane(false);}
        }
        else if (isNonCnctSingleLane) {
            let refElem = dumSingleLaneRef.current; 
            // console.log(refElem);
            if (!txtBxCpShow && refElem) { mouseDnStimulate(refElem, mdPosition); setIsNonCnctSingleLane(false);}
        }  
        // console.log(" mousedn event effect stop");
    }, [isCnctnHd, isCnctnTl, isCnctnBdy, isNonCnctnBdy, isNonCnctSwimLane, isNonCnctSingleLane, isSlctdLst, showDummy, txtBxCpShow, mdPosition]);

    // to trigger custom event after drag and run globalElementsModifierParent after drag is finished
    useEffect (()=>{
        // console.log(" drgFnsh event effect start", drgFnsh);
        drgFnsh && drgFnshFunc(document);
        // console.log(" drgFnsh event effect stop");
    },[drgFnsh])

    //drag resize function for dummy element
    const dragResizeDummy1 = useCallback(
        e => dragResizeFunction1 (e, slctdElemId, setSlctdElemId, dummyElementStyle, setDummyElementStyle, setDrgFnsh, setWrnFlag, setGlobalElementIdMaps)
        ,[dummyElementStyle, slctdElemId, setSlctdElemId, setWrnFlag, setGlobalElementIdMaps]);
    const dragResizeDummy2 = useCallback(
        e => dragResizeFunction2 (e, slctdElemId, setSlctdElemId, dummyElementStyle, setDummyElementStyle, setDrgFnsh, setClsInvld, setWrnFlag)
        ,[dummyElementStyle, slctdElemId, setSlctdElemId, setWrnFlag]);
    const dragResizeDummy3 = useCallback(
        e => dragResizeFunction3 (e, slctdElemId, setSlctdElemId, dummyElementStyle, setDummyElementStyle, setDrgFnsh, setClsInvld, setWrnFlag)
        ,[dummyElementStyle, slctdElemId, setSlctdElemId, setWrnFlag]);
    const dragResizeDummy4 = useCallback(
        e => dragResizeFunction4 (e, slctdElemId, setSlctdElemId, dummyElementStyle, setDummyElementStyle, setDrgFnsh, setClsInvld, setWrnFlag)
        ,[dummyElementStyle, slctdElemId, setSlctdElemId, setWrnFlag]);

    //takes care of removing dummy and placing its details back to the original element(for connector)
    const deleteDummy = useCallback((e)=>{
        x_deleteDummy(e, globalElementsModifierParent, globalTextUpdaterParent,showDummy, setShowDummy, setSlctdElemId, showIpBx, setShowIpBx, 
            IsConnector, showDummyList, setShowDummyList, setSlctdIdList)
    }, [IsConnector, globalElementsModifierParent, globalTextUpdaterParent, showDummy, setShowDummy, setSlctdElemId, showIpBx, showDummyList, setShowDummyList, setSlctdIdList]);

    // inputBox
    const handleInputChange = (e) => {setInputTxt(textareaRef.current.value);}
    const adjustTxtAreaHt = useCallback(() => { x_adjustTxtAreaHt(textareaRef);},[])

    const insertTextFunc = useCallback((id) => {
        x_insertTextFunc (id, globalElements, globalElementTexts, globalLanes, setInputTxt, setInputStyle, setShowIpBx, textareaRef)                    
    },[globalElements, globalElementTexts, globalLanes])

    // textBox Copy
    const createTxtBxCopy = useCallback((e)=> {
        x_createTxtBxCopy(e, setMdPositon, setTxtBxCpId, insertTextFunc, isDblClkRef, globalElementTexts,
            setStlCopy, globalElements, setTxtCopy, setTxtBxCpShow, setGlobalElementTexts, showIpBx, globalTextUpdaterParent, setShowIpBx);
    },[insertTextFunc, globalElements, globalElementTexts, setGlobalElementTexts, showIpBx, globalTextUpdaterParent])    

    useEffect(() => {
        // console.log(" mousedn event effect start");
        const txtElem = txtBxCpRef.current;
        txtElem && mouseDnStimulate(txtElem, mdPosition);
        // console.log(" mousedn event effect stop");
    }, [txtBxCpShow, mdPosition]);

    const destroyTxtBxCopy = useCallback(()=> {
        x_destroyTxtBxCopy (txtBxCpId, stlCopy, globalElements, setGlobalElementTexts, setTxtBxCpShow); 
    },[globalElements, txtBxCpId, stlCopy, setGlobalElementTexts])

    const dragTxtBxCopy = useCallback((e) => {
        funcDrag(e, stlCopy, setStlCopy);
    },[stlCopy])

    
    //creating html list of all the objects in globalElements

    let globalElementsIds = useMemo(() => {return (Object.keys(globalElements))}, [globalElements]);

    let htmlObjLst1 = useMemo( () => { return (
        globalElementsIds.filter(x_id=>IsCnctrFunc(x_id)).map((x_id,index)=>{
        const {glbElemId,IsRsz, style} = globalElements[x_id];  
        return(
            IsRsz && <SingleConnector handleMouseDown={dummyStlUpdaterFunc} key={index} id= {glbElemId} line={style}/>
        );    
    }))}, [globalElements, globalElementsIds, dummyStlUpdaterFunc])

    let htmlObjLst1HdTl = useMemo( () => { return (
        globalElementsIds.filter(x_id=>IsCnctrFunc(x_id)).map((x_id,index)=>{
        const {glbElemId,IsRsz, style} = globalElements[x_id];  
        return(
            IsRsz && <ConnectorHdTl handleMouseDown={dummyStlUpdaterFunc} key={index} id= {glbElemId} line={style} chek={false}/>
        );    
    }))}, [globalElements, globalElementsIds, dummyStlUpdaterFunc])

    let htmlObjLst1TxtBoxes = useMemo( () => { return (
        globalElementsIds.filter(x_id=>IsCnctrFunc(x_id)).map((x_id,index)=>{
        const {glbElemId } = globalElements[x_id];  
        let txt, txtStl, show, txtExists = false;
        if (globalElementTexts[x_id]) {
            txtExists = true;
            ({txt, txtStl, show} = globalElementTexts[x_id]);
        }
        return(
            (txtExists && show) && <div key={index} data-id={glbElemId} className='txtBx' style={txtStl} onMouseDown={createTxtBxCopy} >{txt}</div>
        );    
    }))}, [globalElements, globalElementsIds, globalElementTexts, createTxtBxCopy])

    let htmlObjLst2 = useMemo( () => { return (
        globalElementsIds.filter(x_id=>!IsCnctrFunc(x_id) && !IsLaneFunc(x_id)).map((x_id,index)=>{
        const {glbElemId, style} = globalElements[x_id];
        let isTask = IsActivitsFunc(glbElemId) || IsDataObjFunc(glbElemId);
        let txt, txtStl, show, txtExists;
        if (globalElementTexts[x_id]) {
            txtExists = true;
            ({txt, txtStl, show} = globalElementTexts[x_id]);
        }
        return(
            <div onMouseDown={dummyStlUpdaterFunc} key={index} id= {glbElemId} className='dobjct' style={dobjctStylFunc1(style)}>
                <div className={isTask?'dobjct-svg dtask':'dobjct-svg'} style={dobjctStylFunc2(style)}>
                    {(txtExists && isTask) && <div data-id={glbElemId} className='taskBx'>{txt}</div>}
                    {(txtExists && !isTask && show) && <div data-id={glbElemId} className='txtBx' style={txtStl} onMouseDown={createTxtBxCopy} >{txt}</div>}
                </div>                
            </div>
        );
    }))}, [globalElements, globalElementsIds, dummyStlUpdaterFunc, globalElementTexts, createTxtBxCopy])

    let globalLAnesIds = useMemo(() => {return (Object.keys(globalLanes))}, [globalLanes])

    const htmlObjLst3 = useMemo(() => { return (globalLAnesIds?.filter(x_id=>isParentLaneFunc(x_id)).map((x_id,index) => {
        return <SLane key={index} x_id={x_id} slane={globalLanes[x_id]}/>
    }))},[globalLAnesIds, globalLanes]);
    const htmlObjLst4 = useMemo(() => {return (globalLAnesIds?.filter(x_id=>isParentLaneFunc(x_id)).map((x_id,index) => {
        return <SLaneAnchorComp key={index} x_id={x_id} slane={globalLanes[x_id] } insertTextFunc={insertTextFunc} handleMouseDown={dummyStlUpdaterFunc}/>
    }))}, [globalLAnesIds, globalLanes, dummyStlUpdaterFunc, insertTextFunc]);

    //creating html for dummy element on canvas
    let htmlDummyElement1 = IsConnector && showDummy && <SingleConnectorDummy 
        ref={cnctnRef} id={slctdElemId} handleMouseDown={dragResizeDummy1} line={dummyElementStyle} setline={setDummyElementStyle} insertTextFunc={insertTextFunc}/>
    let htmlDummyElement1HdTl = IsConnector && showDummy && <ConnectorHdTl handleMouseDown={hdTlMsDnFunc} id= {slctdElemId} line={dummyElementStyle} chek={true}/>
    let htmlDummyElement2 = !IsConnector && !IsLane && showDummy && <div ref={dumRef} onMouseDown={dragResizeDummy2} onDoubleClick={()=>insertTextFunc(slctdElemId)} className={dummyClassCreator(20,isClsInvld)} style={dummyElementStyle}>
            {isResize && <div className="drsz"></div>}
        </div>;
    let htmlDummyElement3 = isSwimLane && showDummy &&  <>
            <div ref={dumSwimLaneRef} onMouseDown={dragResizeDummy3} onDoubleClick={()=>insertTextFunc(slctdElemId)} className={dummyClassCreator(30,isClsInvld)} style={laneDummyStylFunc1(dummyElementStyle)}></div>
            <div className={dummyClassCreator(31,isClsInvld)} style={laneDummyStylFunc2(dummyElementStyle)} ></div>
        </>
    let htmlDummyElement4 = isSingleLaneRsz && showDummy &&  <div ref={dumSingleLaneRef} onMouseDown={dragResizeDummy4} className={dummyClassCreator(32,isClsInvld)} style={laneDummyStylFunc3(dummyElementStyle)}><div className='resizeLaneAnchorDummy'></div></div>
    let htmlDummyElement5 = (isChildLane || isSingleLaneLft) && showDummy &&  <div className={dummyClassCreator(31,false)} style={laneDummyStylFunc3(dummyElementStyle)}></div>
        
    // creating html for inputbox and textboxcopy on canvas
    let isTaskBool = IsActivitsFunc(txtBxCpId) || IsDataObjFunc(txtBxCpId); 
    let htmlInputBox = showIpBx && <div className='inputBox' style={inputStyle} onMouseDown={e=>e.stopPropagation()}>
        <textarea ref={textareaRef} rows={isTaskBool?4:1} value={inputTxt} onChange={handleInputChange} onKeyDown={adjustTxtAreaHt}/>
        </div>
    let htmlTextBoxCopy = txtBxCpShow && <div ref={txtBxCpRef} className='txtBxCopy' style={stlCopy} onMouseDown={dragTxtBxCopy} onMouseUp={destroyTxtBxCopy} >{txtCopy}</div>

    return (
        <div onMouseDown={deleteDummy} id = "canvas" className="canvas">
            {/* place where the bpmn process model develops */}
            {htmlObjLst2}
            {htmlObjLst4}
            {isSwimLane && htmlDummyElement3}
            {isSingleLaneRsz && htmlDummyElement4}
            {(isChildLane || (isSingleLaneLft)) && htmlDummyElement5}
            {!IsConnector && !IsLane && htmlDummyElement2}    
            <svg className='svgLanesClass' width="100%" height="100%" preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg">
            {htmlObjLst3}
            </svg>        
            <svg id="connectors" className='connectorsClass' width="100%" height="100%" preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg">
            {htmlObjLst1}
            {htmlObjLst3}
            {IsConnector && htmlDummyElement1}
            </svg>
            {showDummyList && <SelectedList dummyStlUpdaterFunc={dummyStlUpdaterFunc} setIsResize={setIsResize} setDummyElementStyle={setDummyElementStyle}/>}
            {htmlObjLst1TxtBoxes}
            {htmlObjLst1HdTl}
            {IsConnector && htmlDummyElement1HdTl}
            {showIpBx && htmlInputBox}
            {txtBxCpShow && htmlTextBoxCopy}
            {/* only for testing*/}
            <button className='testButon' onMouseDown={(e)=>{e.stopPropagation(); e.preventDefault(); console.log(globalElementIdMaps, globalElements, globalLanes, globalElementTexts);}} >test</button>
            <CanvasSize/>
        </div>
    )
  }
  