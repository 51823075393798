import { idMapSetterFunc} from "./Mn_CanvasComp_func";
import { discretize5, idUnderCursor, idUnderPoint, rectify} from "./Mn_CanvasCompUtil_func";
import { simplifyArrayFunc, updatePoints } from "./Mn_SingleConnector_func";

function dragResizeFunction1 (e, x_slctdElemId, x_setSlctdElemId, x_dummyElementStyle, x_setDummyElementStyle, x_setDrgFnsh, x_setWrnFlag, x_setGlobalElementIdMaps) {
    e.stopPropagation();
    e.preventDefault();
    let selectedElement = e.target;
    let xx_slctdElemId = (x_slctdElemId.slice(3) === 'newElement') ? x_slctdElemId.slice(3) : x_slctdElemId;
    let len = x_dummyElementStyle['pts'].length;
    // console.log(x_slctdElemId, selectedElement, xx_slctdElemId); 

    function dummySetterFunction(x_index,moveX,moveY,initParam){
        x_setDummyElementStyle((prevDummy)=>{ 
            let newDummy = {...prevDummy};
            newDummy.pts[x_index][0] = rectify(initParam['X']+moveX);
            newDummy.pts[x_index][1] = rectify(initParam['Y']+moveY);
            
            return newDummy
        })} 

    function dummySetterFunctionAll(x_index, moveX, moveY, initParams) {
        x_setDummyElementStyle((prevDummy) => {
          let newDummy = { ...prevDummy };
      
          // Update newDummy.pts using initial and final points
          for (let i = 0; i < newDummy.pts.length; i++) {
            newDummy.pts[i][0] = rectify(initParams[i][0]+moveX);
            newDummy.pts[i][1] = rectify(initParams[i][1]+moveY);
          }
      
          return newDummy;
        });
      }
        
    // function uponMsMv() {} may be needed later

    function uponMsUp1(x_idPrsnt, x_index) {
        // if moved uder same id like in same object or outside, update points will not take effect.. held intentionally
        //  also negate the possibility of same id in both ids fields

        let idToremove = null, idToAdd = x_idPrsnt, isIndxHd = x_index?1:0, idxs = x_index?'idfs':'idis';        
        if (x_dummyElementStyle['ids'][isIndxHd]) {idToremove = x_dummyElementStyle['ids'][isIndxHd];}

        idMapSetterFunc (idToremove, idToAdd, idxs, x_setGlobalElementIdMaps, x_slctdElemId);
        
        x_setDummyElementStyle((prevDummy) => {
            let { ids, pts } = { ...prevDummy };
            if (ids[isIndxHd] !== x_idPrsnt) {
                ids[isIndxHd] = x_idPrsnt;
                pts = updatePoints(x_slctdElemId, ids, pts);
            }
            return { ids, pts };
        }); 
    }       

    function uponMsUp2(x_idiPrsnt, x_idfPrsnt) {        
        x_setDummyElementStyle((prevDummy) => {
            let { ids, pts } = { ...prevDummy };
            if ((ids[0] !== x_idiPrsnt) || (ids[1] !== x_idfPrsnt)){
                ids = [x_idiPrsnt, x_idfPrsnt];
                pts = updatePoints(x_slctdElemId, ids, pts);
            }
            return { ids, pts };
        });

        if (xx_slctdElemId === 'newElement') {
            x_setSlctdElemId('newElement');
            x_setDrgFnsh(prevState => prevState+1);
            x_setWrnFlag(false);
        }          
        else {
            let idiToremove = null, idfToremove = null, idiToAdd = x_idiPrsnt, idfToAdd = x_idfPrsnt;        
            if (x_dummyElementStyle['ids'][0]) {idiToremove = x_dummyElementStyle['ids'][0];}
            if (x_dummyElementStyle['ids'][1]) {idfToremove = x_dummyElementStyle['ids'][1];}

            idMapSetterFunc (idiToremove, idiToAdd, 'idis', x_setGlobalElementIdMaps, x_slctdElemId);
            idMapSetterFunc (idfToremove, idfToAdd, 'idfs', x_setGlobalElementIdMaps, x_slctdElemId);
        }      
    }

    function uponMsUp3() {
        x_setDummyElementStyle((prevDummy) => {
            let { ids, pts } = { ...prevDummy };
            pts = simplifyArrayFunc(pts);
            return { ids, pts };
        });
    } 

    if (selectedElement.classList.contains('cnctnObjctHead')) {funcDrag(e,len-1,dummySetterFunction,undefined,uponMsUp1);}
    else if (selectedElement.classList.contains('cnctnObjctTail')) {funcDrag(e,0,dummySetterFunction,undefined,uponMsUp1);}
    else if (selectedElement.classList.contains('cnctnPt')) {
        let ptIndx = selectedElement.id.slice(6);
        funcDrag(e,ptIndx,dummySetterFunction,undefined,uponMsUp3);
    }    
    else if (selectedElement.classList.contains('cnctnObjct')) { 
        funcDrag(e,-1,dummySetterFunctionAll,undefined,uponMsUp2)
    }

    function funcDrag(e,index,setterFunc,x_uponMsMv,x_uponMsUp) {
        // console.log("dragResizeFunction start");
    
        let startX = e.clientX, startY = e.clientY ;
        let idPrsnt = null, idiPrsnt = null, idfPrsnt = null;
        let dummyInitParams ;
        if (index === -1) { dummyInitParams = x_dummyElementStyle.pts.map((point) => [...point])}
        else {dummyInitParams = {X: x_dummyElementStyle.pts[index][0], Y: x_dummyElementStyle.pts[index][1]};}
    
        window.addEventListener("mousemove", funcMouseMove);
        window.addEventListener("mouseup", funcMouseUp);
    
        function funcMouseMove(e){
            let moveX = e.clientX - startX; moveX = discretize5(moveX);
            let moveY = e.clientY - startY; moveY = discretize5(moveY);
    
            setterFunc(index,moveX,moveY,dummyInitParams);    
            if (x_uponMsMv) {x_uponMsMv();}          
        }
    
        function funcMouseUp(e){        
            window.removeEventListener("mousemove", funcMouseMove);
            window.removeEventListener("mouseup", funcMouseUp);  
            
            if (x_uponMsUp) { 
                if (index === 0 || index === len-1) {
                    idPrsnt = idUnderCursor(e, 'dobjct');
                    x_uponMsUp(idPrsnt, index);
                }
                else if (index === -1) {
                    let l = dummyInitParams.length;
                    let delX = e.clientX - startX, delY = e.clientY - startY;
                    let xi = dummyInitParams[0][0] + delX, yi = dummyInitParams[0][1] + delY;
                    let xf = dummyInitParams[l-1][0] + delX, yf = dummyInitParams[l-1][1] + delY;
                    idiPrsnt = idUnderPoint([xi, yi], 'dobjct');
                    idfPrsnt = idUnderPoint([xf, yf], 'dobjct');
                    x_uponMsUp(idiPrsnt, idfPrsnt);
                }
                else {x_uponMsUp()}
            }

            // console.log("dragResizeFunction stop");           
        }
    }
    
}

export {dragResizeFunction1};