import { forwardRef, useMemo, useRef, useImperativeHandle } from "react";
import { hdGenFunc, TlGenFunc, calcAngleDegrees, ptIndxInArrFunc } from "./Mn_SingleConnector_func";
import { discretize5, mouseDnStimulateParent } from "./Mn_CanvasCompUtil_func";

const SingleConnectorDummy = forwardRef(
    function SingleConnectorDummy(props, ref){

        const {id, line, setline, handleMouseDown, handleMouseUp, insertTextFunc} = props;
        //  handleMouseUp may be used later but unused now

        const cnctnHdRef = useRef(null);
        const cnctnTlRef = useRef(null);
        const cnctnBdyRef = useRef(null);

        useImperativeHandle(ref, () => ({
            hdMsDn: (coord, clBkFunc) => {
                let refElem = cnctnHdRef.current;
                mouseDnStimulateParent(refElem, coord, clBkFunc);
            },
            tlMsDn: (coord, clBkFunc) => {
                let refElem = cnctnTlRef.current;
                mouseDnStimulateParent(refElem, coord, clBkFunc);
            },
            bdyMsDn: (coord, clBkFunc) => {
                let refElem = cnctnBdyRef.current;
                mouseDnStimulateParent(refElem, coord, clBkFunc);
            },
          }));

        const cnctrType = id=>Number(id.slice(2,3));
        let typ = (cnctrType(id));
        // typ = { 0 : "Sequence Flow", 1 : "Association (Undirected)", 2 : "Association (Unidirectional)", 3 : "Message Flow", 4 : "Association (Bidirectional)" }

        const insertTextFuncParent = (e) => {
            e.stopPropagation();
            insertTextFunc(id)
        }

        let points = useMemo(() => [...line['pts']],[line]);
        const insertPt = (e) => {
            e.stopPropagation();
            if (typ === 0) {
                let point = [discretize5(e.clientX - 250), discretize5(e.clientY - 84)];
                let arr = [...points];
                let index = ptIndxInArrFunc(point, arr);
                if (index) {
                    arr.splice(index, 0, point);
                    setline((prevline)=>{
                        let newline = {...prevline};
                        newline.pts = arr;
                        return newline
                    })
        }}}

        let len = points.length;
        let [xi,yi] = points[0], [xf, yf] = points[len-1];

        let head = hdGenFunc(typ, points[len-1]);
        let tail = TlGenFunc(points[0]);

        let angl1 = calcAngleDegrees(points[0],points[1]);
        let angl2 = calcAngleDegrees(points[len-2],points[len-1]);
        let trnsfrm1 =`rotate(${angl1}, ${xi}, ${yi})`;
        let trnsfrm2 =`rotate(${angl2}, ${xf}, ${yf})`;

        let pointsA, pointsB;// pointsA for point insertion, B for text insertion
        if (len <= 3) {
            let xc = (2*(points[0][0]) + points[1][0])/3;
            let yc = (2*(points[0][1]) + points[1][1])/3;
            pointsA = [[xc,yc],...points.slice(1)];
            pointsB = [points[0],[xc,yc]];
        }
        else {
            pointsA = [...points.slice(1)];
            pointsB = [points[0],points[1]];
        }

        const intermediatePts = points.slice(1,-1).map((elem, index)=>{
            return(<g key={index}>
                <circle r="3" cx={elem[0]} cy={elem[1]} fill="grey" stroke="black" strokeWidth="1" vectorEffect="non-scaling-stroke"/>
                <circle className="cnctnPt" id={'crclPt' + (index + 1)} r="10" cx={elem[0]} cy={elem[1]} fill="transparent" fillOpacity="0.1" stroke="gray" strokeWidth="0" vectorEffect="non-scaling-stroke"/>

            </g>                
            );
        })

        return (
        <g className="dobjct-connector-copy dummyObject" onMouseDown={handleMouseDown} onMouseUp={handleMouseUp}>            
            {/* main line */}
            <polyline className="cnctnObjct" points={points} fill="none" stroke="black" strokeDasharray={typ!==0?"3,4":'none'} strokeWidth="1.5" vectorEffect="non-scaling-stroke"/>
            {/* white line */}
            <polyline ref={cnctnBdyRef} className="cnctnObjct" onDoubleClick={insertPt} points={pointsA} fill="none" stroke="lightgreen" strokeOpacity="0.3" strokeWidth="10" vectorEffect="non-scaling-stroke"/>
            {/* big circles for area , head and tail */}
            <circle className="cnctnObjctHead" ref={cnctnHdRef} r="10" cx={xf} cy={yf} fill="transparent" fillOpacity="0.1" stroke="gray" strokeWidth="0" vectorEffect="non-scaling-stroke"/>
            <circle className="cnctnObjctTail" ref={cnctnTlRef} r="10" cx={xi} cy={yi} fill="transparent" fillOpacity="0.1" stroke="gray" strokeWidth="0" vectorEffect="non-scaling-stroke"/>
            {/* arrow head and tail */}
            {typ!==1 && <polyline className="cnctnObjctHead" points={head} transform={trnsfrm2} fill={typ===0?"black":(typ===3?"white":"none")} stroke="black" strokeWidth="1.5" vectorEffect="non-scaling-stroke"/>}
            {typ===4 && <polyline className="cnctnObjctTail" points={tail} transform={trnsfrm1} fill="none" stroke="black" strokeWidth="1.5" vectorEffect="non-scaling-stroke"/>}
            {typ===3 && <circle className="cnctnObjctTail" r="5" cx={xi -3 } cy={yi} transform={trnsfrm1} fill="white" stroke="black" strokeWidth="1" vectorEffect="non-scaling-stroke"/>}
            {/* for textBox  ****to be checked the disadvantages of voth classes***/}
            <polyline className="cnctnTxtBx cnctnObjct" onDoubleClick={insertTextFuncParent} points={pointsB} fill="none" stroke="lightgrey" strokeOpacity="0.3" strokeWidth="10" vectorEffect="non-scaling-stroke"/>            
            {intermediatePts}
        </g>
    )}
)

export default SingleConnectorDummy;